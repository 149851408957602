import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card } from 'react-bootstrap';
import ReactApexChart from 'react-apexcharts'; 
import { API_BASE_URL } from '../../../../../app/config';
const GET_SERVER_LOG_LIST = `${API_BASE_URL}/sms/getServerLogList`

type Props = {
  className: string;
  description: string;
  color: string;
  img: string;
};

type ServerLog = {
  id: number;
  ipAddress: number;
  hostName: string;
  totalStorage: string;
  usedStorage: string;
  usedStoragePercentage: string;
  availableStorage: string;
  serverName: string;
  rootDirectoryStorageUsed: string;
  homeDirectoryStorageUsed: string;
  varDirectoryStorageUsed: string;
};

const CardsWidget8 = ({ className, description, color, img }: Props) => {
  const [serverLogs, setServerLogs] = useState<ServerLog[]>([]);
  const [error, setError] = useState<string | null>(null);

  const fetchData = () => {
    axios
      .get(GET_SERVER_LOG_LIST)
      .then((response) => {
        setServerLogs(response.data);
      })
      .catch((error) => {
        setError(error.message);
        console.error('Error fetching data:', error);
      });
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  const displayedLogs = serverLogs.slice(0, 10);

  return (
   <div className={`card-deck ${className} `}>
    {displayedLogs.map((serverLog) => (
      <Card
        key={serverLog.id}
        className={`text-center border mb-4 border border-info ${
          parseFloat(serverLog.rootDirectoryStorageUsed) > 80 ||
          parseFloat(serverLog.homeDirectoryStorageUsed) > 80 ||
          parseFloat(serverLog.varDirectoryStorageUsed) > 80
            ? 'bg-danger'
            : 'bg-light'
        }`}
        style={{ width: '29rem' }}
      >
        <Card style={{ width: '29rem' }}>
          <Card.Body className="p-3 bg-secondary border border-info rounded ">
            <Card.Title className="bg-success text-white">
              <span className=" text-info">ServerName</span>: {serverLog.serverName}{' '}
              <span className=" text-info">HostName</span>: {serverLog.serverName}
            </Card.Title>
            <Card.Text className="">
              <Card.Text>
                <span className="text-primary">Ip Address</span>: {serverLog.ipAddress}
              </Card.Text>
              <Card.Text>
                <span className="text-primary">Total Storage</span>: {serverLog.totalStorage}
                <span className="text-primary">GB</span>
              </Card.Text>
              <Card.Text>
                <span className="text-primary">Used Storage</span>: {serverLog.usedStorage}
                <span className="text-primary">GB</span>
              </Card.Text>
              <Card.Text>
                <span className="text-primary">Root Directory Storage Used</span>:{' '}
                {serverLog.rootDirectoryStorageUsed}
                <span className="text-primary">%</span>
                {parseFloat(serverLog.rootDirectoryStorageUsed) > 80 && (

                  <span className="text-danger warning">
                    <i className="bi bi-radioactive text-danger">Exceeds 80%  </i>
                  </span>
                )}
              </Card.Text>
              <Card.Text>
                <span className=" text-primary">Home Directory Storage Used</span>:{' '}
                {serverLog.homeDirectoryStorageUsed}
                <span className=" text-primary">%</span>
                {parseFloat(serverLog.homeDirectoryStorageUsed) > 80 && (
                  <span className="text-danger warning">
                    <i className="bi bi-radioactive text-danger">Exceeds 80%  </i>
                  </span>
                )}
              </Card.Text>
              <Card.Text>
                <span className="text-primary">Var Directory Storage Used</span>:{' '}
                {serverLog.varDirectoryStorageUsed}
                <span className="text-primary">%</span>
                {parseFloat(serverLog.varDirectoryStorageUsed) > 80 && (
                  <span className="text-danger warning">
                    <i className="bi bi-radioactive text-danger">Exceeds 80%  </i>
                  </span>
                )}
              </Card.Text>
              <Card.Text>
                <span className="text-primary">Available Storage</span>:{' '}
                {serverLog.availableStorage}
                <span className="text-primary">GB</span>
              </Card.Text>
            </Card.Text>
          </Card.Body>
        </Card>

        {/* Render ApexChart for storage information */}
        <ReactApexChart
          options={{
            xaxis: {
              categories: [
                'Total Storage',
                'Used Storage',
                'Available Storage',
                'Root Directory Storage Used ', 
                'Home Directory Storage Used ', 
                'Var Directory Storage Used ',  
              ],
            },
            dataLabels: {
              enabled: true,
              formatter: function (value, { dataPointIndex }) {
                if (dataPointIndex >= 3 && dataPointIndex <= 5) {
                  return value + '%'; 
                } else {
                  return value + 'GB'; 
                }
              },
            },
          }}
          series={[
            {
              name: 'Storage',
              data: [
                Number(serverLog.totalStorage),
                Number(serverLog.usedStorage),
                Number(serverLog.availableStorage),
                Number(serverLog.rootDirectoryStorageUsed),
                Number(serverLog.homeDirectoryStorageUsed),
                Number(serverLog.varDirectoryStorageUsed),
              ],
            },
          ]}
          type="bar"
          height={320}
        />
      </Card>
    ))}
  </div>
  );
};

export { CardsWidget8 };
