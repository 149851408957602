import React, {FC} from 'react'

const ToggleHelpDrawer: FC = () => (
  <a
    id='kt_help_toggle'
    className=' '
   
  >
   
  </a>
)

export {ToggleHelpDrawer}
