import clsx from 'clsx';
import { KTIcon, toAbsoluteUrl } from '../../../helpers';
import { HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher } from '../../../partials';
import { useLayout } from '../../core';
import Marquee from 'react-fast-marquee';
import 'react-clock/dist/Clock.css';
import { useEffect, useState } from 'react';


const itemClass = 'ms-1 ms-lg-3';
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px';
const userAvatarClass = 'symbol-35px symbol-md-40px';
const btnIconClass = 'fs-1';

const Navbar = () => {
  const [currentTime, setCurrentTime] = useState(new Date().toLocaleString());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date().toLocaleString());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const { config } = useLayout();

  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <div>
         <div className='text-primary h-auto d-inline-block' ></div></div>



       <div className={clsx('app-navbar-item', itemClass)}>
         <div className="h-30 d-inline-block border border-info rounded" style={{ width: '150px', backgroundColor: 'rgba(0,0,0,.1)' }}>
      
          <span className='text-primary '>{currentTime} </span>
        
          </div>
          </div>
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          {/* Add an icon or component here */}
        </div>
        {/* <HeaderNotificationsMenu /> */}
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img src={toAbsoluteUrl('/media/avatars/iatLogo90x49.png')} alt='' />
        </div>
        <HeaderUserMenu />
      </div>

      
    </div>
  );
};

export { Navbar };
