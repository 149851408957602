import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'



import CloneUser from '../modules/Users/CloneUser'
import CreateUser from '../modules/Users/CreateUser'
import UserList from '../modules/Users/UserList'
import { Log } from '../pages/log/Log'
import { ServerInfo } from '../pages/ServerInfo/ServerInfo'













const PrivateRoutes = () => {

  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}

        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />


        <Route
          path='crafted/contact&group/*'
          element={
            <SuspensedView>

            </SuspensedView>
          }>

        </Route>
        <Route
          path='crafted/users/userlist/*'
          element={
            <SuspensedView>
              <UserList />
            </SuspensedView>
          }>
        </Route>
        <Route
          path='crafted/users/cloneuser/*'
          element={
            <SuspensedView>
              <CloneUser />
            </SuspensedView>
          }>
        </Route>
        <Route
          path='crafted/users/createuser/*'
          element={
            <SuspensedView>
              <CreateUser />
            </SuspensedView>
          }>
        </Route>
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
         <Route
        path='crafted/log/*'
        element={
          <SuspensedView>
            <Log className={''} description={''} color={''} img={''}></Log>
          </SuspensedView>
        }>
         
        </Route>

        <Route
        path='crafted/serverinfo/*'
        element={
          <SuspensedView>
            <ServerInfo className={''} description={''} color={''} img={''} ></ServerInfo>
          </SuspensedView>
        }>
         
        </Route>
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
