import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './pagination.css';
import { Pagination, Table } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { API_BASE_URL } from '../../../app/config';
const GET_SERVER_LOG_LIST = `${API_BASE_URL}/sms/getServerLogList`

type Props = {
  className: string;
  description: string;
  color: string;
  img: string;
};

type ServerLog = {
  id: number;
  ipAddress: number;
  hostName: string;
  serverName: string;
  totalStorage: string;
  usedStorage: string;
  usedStoragePercentage: string;
  availableStorage: string;
  rootDirectoryStorageUsed: string;
  homeDirectoryStorageUsed: string;
  varDirectoryStorageUsed: string;
};

const ServerInfo = ({ className }: Props) => {
  const [serverLogs, setServerLogs] = useState<ServerLog[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc'); // Added sorting state
  const itemsPerPage = 10;
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const fetchData = () => {
    setIsLoading(true);
    axios
      .get(GET_SERVER_LOG_LIST)
      .then(response => {
        setServerLogs(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSort = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  // Sorting logic
  const sortedLogs = [...serverLogs].sort((a, b) => {
    if (sortOrder === 'asc') {
      return a.hostName.localeCompare(b.hostName);
    } else {
      return b.hostName.localeCompare(a.hostName);
    }
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredLogs = sortedLogs.filter(serverLog =>
    serverLog.hostName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const currentItems = filteredLogs.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePaginationClick = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(filteredLogs.length / itemsPerPage);

  // Function to generate an array of page numbers with ellipses
  const generatePageNumbers = () => {
    const pageNumbers = [];
    const maxPageLinks = 5; // Maximum number of visible page links

    if (totalPages <= maxPageLinks) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= Math.floor(maxPageLinks / 2)) {
        for (let i = 1; i <= maxPageLinks; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push('...');
        pageNumbers.push(totalPages);
      } else if (currentPage >= totalPages - Math.floor(maxPageLinks / 2)) {
        pageNumbers.push(1);
        pageNumbers.push('...');
        for (let i = totalPages - maxPageLinks + 1; i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        pageNumbers.push(1);
        pageNumbers.push('...');
        for (let i = currentPage - Math.floor(maxPageLinks / 2); i <= currentPage + Math.floor(maxPageLinks / 2); i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push('...');
        pageNumbers.push(totalPages);
      }
    }

    return pageNumbers;
  };

  return (
    <div className={`table-container ${className}`}>
      <div className="d-flex justify-content-between align-items-center mt-3">
        <input
          type="text"
          placeholder="Search by Host Name"
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
        />

      </div>
      <Table striped bordered hover>
        {isLoading ? ( // loading spinner
          <div className="d-flex justify-content-center mt-3">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <Table striped bordered hover>
            <thead>
              <tr className="border border-info">
                <th>Host Name <Link onClick={handleSort} to={''}><i className="bi bi-arrow-down-up"></i> {sortOrder === 'asc' ? '' : ''}</Link></th>
                <th>Server Name <Link onClick={handleSort} to={''}><i className="bi bi-arrow-down-up"></i> {sortOrder === 'asc' ? '' : ''}</Link></th>
                <th>IP Address <Link onClick={handleSort} to={''}><i className="bi bi-arrow-down-up"></i> {sortOrder === 'asc' ? '' : ''}</Link></th>
                <th>Total Storage (GB)<Link onClick={handleSort} to={''}><i className="bi bi-arrow-down-up"></i> {sortOrder === 'asc' ? '' : ''}</Link></th>
                <th>Used Storage (GB)<Link onClick={handleSort} to={''}><i className="bi bi-arrow-down-up"></i> {sortOrder === 'asc' ? '' : ''}</Link></th>
                <th>Used Storage Percentage (%)<Link onClick={handleSort} to={''}><i className="bi bi-arrow-down-up"></i> {sortOrder === 'asc' ? '' : ''}</Link></th>
                <th>Available Storage (GB)<Link onClick={handleSort} to={''}><i className="bi bi-arrow-down-up"></i> {sortOrder === 'asc' ? '' : ''}</Link></th>
                <th>Root Directory Storage Used (%)<Link onClick={handleSort} to={''}><i className="bi bi-arrow-down-up"></i> {sortOrder === 'asc' ? '' : ''}</Link></th>
                <th>Home Directory Storage Used (%)<Link onClick={handleSort} to={''}><i className="bi bi-arrow-down-up"></i> {sortOrder === 'asc' ? '' : ''}</Link></th>
                <th>Var Directory Storage Used (%)<Link onClick={handleSort} to={''}><i className="bi bi-arrow-down-up"></i> {sortOrder === 'asc' ? '' : ''}</Link></th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map(serverLog => (
                <tr key={serverLog.id} className="border border-secondary">
                  <td>{serverLog.hostName}</td>
                  <td>{serverLog.serverName}</td>
                  <td>{serverLog.ipAddress}</td>
                  <td>{serverLog.totalStorage}</td>
                  <td>{serverLog.usedStorage}</td>
                  <td>{serverLog.usedStoragePercentage}</td>
                  <td>{serverLog.availableStorage}</td>
                  <td>
                    {parseFloat(serverLog.rootDirectoryStorageUsed) > 90 ? (
                      <>
                        <span>{serverLog.rootDirectoryStorageUsed}</span>
                        <span className="text-primary">%</span>
                        <br />
                        <span className='bi bi-radioactive text-danger'>Exceeds 90%</span>
                      </>
                    ) : (
                      serverLog.rootDirectoryStorageUsed
                    )}
                  </td>
                  <td>{serverLog.homeDirectoryStorageUsed}</td>
                  <td>
                    {parseFloat(serverLog.varDirectoryStorageUsed) > 90 ? (
                      <>
                        <span>{serverLog.varDirectoryStorageUsed}</span>
                        <span className="text-primary">%</span>
                        <br />
                        <span className='bi bi-radioactive text-danger'>Exceeds 90%</span>
                      </>
                    ) : (
                      serverLog.varDirectoryStorageUsed
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Table>
      <div className="d-flex justify-content-center mt-3">
        <Pagination>
          <Pagination.Prev
            onClick={() => handlePaginationClick(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {generatePageNumbers().map((pageNumber, index) => (
            <Pagination.Item
              key={index}
              active={pageNumber === currentPage}
              onClick={() => {
                if (typeof pageNumber === 'number') {
                  handlePaginationClick(pageNumber);
                }
              }}
            >
              {pageNumber}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() => handlePaginationClick(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      </div>
    </div>
  );
};

export { ServerInfo };
