/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='bi bi-file-earmark-bar-graph'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi bi-file-earmark-bar-graph'
      />
     <SidebarMenuItemWithSub to='/crafted/Log' title='Log' fontIcon='bi bi-database-fill-gear' icon='bi bi-database-fill-gear'>
      <SidebarMenuItem to='/crafted/Log/log' title='Log' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='/crafted/ServerInfo' title='ServerInfo' fontIcon='bi bi-hdd-fill' icon='bi bi-hdd-fill'>
      <SidebarMenuItem to='/crafted/ServerInfo/ServerInfo' title='ServerInfo' hasBullet={true} />
      </SidebarMenuItemWithSub>
      

    </>
  )
}

export {SidebarMenuMain}
