import React, {FC} from 'react'

const PurchaseButton: FC = () => (
  <a
    href={process.env.REACT_APP_PURCHASE_URL}
    className=''
  >
    
  </a>
)

export {PurchaseButton}
