/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-body');
    return () => {
      document.body.classList.remove('bg-body');
    };
  }, []);

  return (
    <div
    className='d-flex justify-content-end' // Align content to the right
    style={{
      backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
      backgroundRepeat: 'no-repeat',
      backgroundPositionY: 'bottom',
      backgroundSize: 'contain',
      backgroundAttachment: 'fixed',
      minHeight: '100vh', // Ensure full viewport height
    }}
  >
   
   <div className='d-flex flex-column flex-column-fluid p-10 pb-lg-20 align-items-end'>
 
        <div
          className='bg-body rounded shadow-sm p-10 p-lg-15 border border-info'
          style={{
            width: '350px', // Adjust the width as needed
          }}
        >
          <Outlet />
        </div>
       
      </div>
     
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
