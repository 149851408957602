import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './pagination.css';
import { Pagination, Table } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';
import './log-responsive.css';
import { API_BASE_URL } from '../../../app/config';
const GET_SERVER_LOG_LIST = `${API_BASE_URL}/sms/getServerLogList`

type Props = {
  className: string;
  description: string;
  color: string;
  img: string;
};

type ServerLog = {
  id: number;
  ipAddress: number;
  hostName: string;
  totalStorage: string;
  usedStorage: string;
  usedStoragePercentage: string;
  availableStorage: string;
};

const Log = ({ className }: Props) => {
  const [serverLogs, setServerLogs] = useState<ServerLog[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const itemsPerPage = 10; 
  const [isLoading, setIsLoading] = useState<boolean>(true);


  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);


  //api call
  const fetchData = () => {
    setIsLoading(true); // Set loading to true before fetching data
    axios
      .get(GET_SERVER_LOG_LIST)
      .then(response => {
        setServerLogs(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false after fetching data
      });
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredLogs = serverLogs.filter(serverLog =>
    serverLog.hostName.toLowerCase().includes(searchQuery.toLowerCase())
  );


  //pagination method
  
  const currentItems = filteredLogs.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePaginationClick = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(serverLogs.length / itemsPerPage);

  // Function to generate an array of page numbers with ellipses
  const generatePageNumbers = () => {
    const pageNumbers = [];
    const maxPageLinks = 5; // Maximum number of visible page links

    if (totalPages <= maxPageLinks) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= Math.floor(maxPageLinks / 2)) {
        for (let i = 1; i <= maxPageLinks; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push('...');
        pageNumbers.push(totalPages);
      } else if (currentPage >= totalPages - Math.floor(maxPageLinks / 2)) {
        pageNumbers.push(1);
        pageNumbers.push('...');
        for (let i = totalPages - maxPageLinks + 1; i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        pageNumbers.push(1);
        pageNumbers.push('...');
        for (let i = currentPage - Math.floor(maxPageLinks / 2); i <= currentPage + Math.floor(maxPageLinks / 2); i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push('...');
        pageNumbers.push(totalPages);
      }
    }
    return pageNumbers;
  };
  return (
    <div className={`table-container ${className}`}>
       <div className="d-flex justify-content-center mt-3">
        <input
          type="text"
          placeholder="Search by Host Name"
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
        />
      </div>
      <Table striped bordered hover>
      {isLoading ? ( //  loading spinner
        <div className="d-flex justify-content-center mt-3">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <Table striped bordered hover>
       <Table striped bordered hover>
        <thead>
          <tr className="border border-info">
            <th>Host Name</th>
            <th>IP Address</th>
            <th>Total Storage (GB)</th>
            <th>Used Storage (GB)</th>
            <th>Used Storage Percentage (%)</th>
            <th>Available Storage (GB) </th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map(serverLog => (
            <tr key={serverLog.id}>
              <td>{serverLog.hostName}</td>
              <td>{serverLog.ipAddress}</td>
              <td>{serverLog.totalStorage}</td>
              <td>{serverLog.usedStorage}</td>
              <td>{serverLog.usedStoragePercentage}</td>
              <td>{serverLog.availableStorage}</td>
            </tr>
          ))}
        </tbody>
      </Table>
        </Table>
      )}
      </Table>
      <div className="d-flex justify-content-center mt-3">
        <Pagination>
          <Pagination.Prev
            onClick={() => handlePaginationClick(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {generatePageNumbers().map((pageNumber, index) => (
            <Pagination.Item
              key={index}
              active={pageNumber === currentPage}
              onClick={() => {
                if (typeof pageNumber === 'number') {
                  handlePaginationClick(pageNumber);
                }
              }}
            >
              {pageNumber}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() => handlePaginationClick(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      </div>
     
    </div>
  );
};

export { Log };
