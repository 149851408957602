import React, { FC, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../_metronic/layout/core';
import { CardsWidget8 } from '../../../_metronic/partials/widgets/_new/cards/CardWidget8';
import { CardsWidget9 } from '../../../_metronic/partials/widgets/_new/cards/CardsWidget9';
import { Spinner } from 'react-bootstrap';
import './dashboardStyles.css';
const DashboardPage: FC = () => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const firstRowData = {
    id: 1,
    title: 'Card Title 1',
    content: 'Card Content 1',
    imageUrl: 'url_for_image_1',
  };
  const secondRowData = {
    id: 2,
    title: 'Card Title 2',
    content: 'Card Content 2',
    imageUrl: 'url_for_image_2',
  };

  useEffect(() => {
    // Simulate loading delay (replace with actual data fetching)
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center mt-3">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 mb-4">
            <CardsWidget8
              className="h-md-50 mb-5 mb-xl-10"
              description={`HostName: ${firstRowData.title}`}
              color="#F1416C"
              img={firstRowData.imageUrl}
            />
          </div>
          <div className="col-12 col-md-6 mb-4">
            <CardsWidget9
              className="h-md-50 mb-5 mb-xl-10"
              description={`HostName: ${secondRowData.title}`}
              color="#F1416C"
              img={secondRowData.imageUrl}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const DashboardWrapper: FC = () => {
  const intl = useIntl();

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  );
};

export { DashboardWrapper };
